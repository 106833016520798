@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.info {
  padding-top: clamp(40px, 7vw, 92px);

  &__nav {
    margin-bottom: clamp(40px, 7vw, 94px);

    h2 {
      margin-bottom: clamp(24px, 2.8vw, 40px);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: clamp(40px, 8vw, 115px) clamp(24px, 4vw, 56px);
    max-width: 1150px;
    width: 100%;
  }

  &__item {
    position: relative;
    padding-left: 80px;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
    }

    &--expertise::before {
      background-image: url("../images/icons/expertise-icon.svg");
    }

    &--accommodations::before {
      background-image: url("../images/icons/accommodations-icon.svg");
    }

    &--personalized::before {
      background-image: url("../images/icons/personalized-icon.svg");
    }

    &--sustainable::before {
      background-image: url("../images/icons/sustainable-icon.svg");
    }

    h3 {
      margin-bottom: 12px;
      padding-top: 5px;
      font-size: clamp(22px, 2.6vi, 28px);
      line-height: 1.1;
      font-weight: var(--fw-500);
      color: var(--color-blue-900);
    }

    p {
      color: var(--color-grey-870);
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }
  }

  @include media(mobile-s) {
    &__item {
      padding-left: 50px;

      &::before {
        width: 40px;
        height: 40px;
      }
    }
  }
}