/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --content-width: 1240px;
  --content-wide: 1360px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-wide: calc(var(--content-wide) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;

  // font-weight
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;

  // color
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dark: #0D0D0D;

  --color-grey-900: #161616;
  --color-grey-960: #00000099;
  --color-grey-870: rgba(31, 31, 31, 0.7);
  --color-grey-700: #31414C;
  --color-grey-400: #C4C4C4;
  --color-grey-200: #F2F2F2;

  --color-blue-900: #000B33;
  --color-blue-700: #0C3947;
  --color-blue-600: #007093;
  --color-blue-500: #007AA0;
  --color-blue-400: #2393B7;
  --color-blue-300: #DBF3FB;

  --color-pink-400: #F1AAAD;

  //  radius
  --radius-main: 0.5px;
  --radius-m: 8px;
  --radius-l: 16px;
  --radius-xxl: 60px;
  --radius-full: 50%;

  // shadow
  --shadow-primary: 0px 4px 40px 0px #2B59FF14;

  // gradient
  --gradient-main: linear-gradient(320deg, rgba(12, 57, 71, 0.4) 40%, rgba(0, 0, 0, 0.6) 80%);
}
