@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1240px;
  --content-wide: 1360px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --container-wide: calc(var(--content-wide)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --color-white: #fff;
  --color-black: #000;
  --color-dark: #0d0d0d;
  --color-grey-900: #161616;
  --color-grey-960: #0009;
  --color-grey-870: #1f1f1fb3;
  --color-grey-700: #31414c;
  --color-grey-400: #c4c4c4;
  --color-grey-200: #f2f2f2;
  --color-blue-900: #000b33;
  --color-blue-700: #0c3947;
  --color-blue-600: #007093;
  --color-blue-500: #007aa0;
  --color-blue-400: #2393b7;
  --color-blue-300: #dbf3fb;
  --color-pink-400: #f1aaad;
  --radius-main: .5px;
  --radius-m: 8px;
  --radius-l: 16px;
  --radius-xxl: 60px;
  --radius-full: 50%;
  --shadow-primary: 0px 4px 40px 0px #2b59ff14;
  --gradient-main: linear-gradient(320deg, #0c394766 40%, #0009 80%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-grey-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(18px, 2vi, 23px);
  font-style: normal;
  line-height: 1.2;
}

.page--destinations section#featured .box__nav {
  justify-content: center;
}

.page--destinations section#featured .btn {
  display: none;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.container--wide {
  max-width: var(--container-wide);
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.section {
  padding: clamp(40px, 7vw, 100px) 0;
}

.section--small {
  padding: clamp(30px, 5.6vw, 80px) 0;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .overlay:before {
    background: var(--gradient-main);
  }
}

.tabs__btn {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1 {
  font-size: clamp(32px, 5vi, 70px);
  line-height: .85;
  font-weight: var(--fw-600);
  color: var(--color-white);
  text-transform: uppercase;
}

@media only screen and (max-width: 992px) {
  h1 {
    line-height: 1.2;
  }
}

h2, .accent {
  font-size: clamp(26px, 2.8vi, 40px);
  font-weight: var(--fw-300);
  line-height: 1;
}

h2 span, .accent span {
  color: var(--color-blue-600);
}

@media only screen and (max-width: 992px) {
  h2 span, .accent span {
    padding-top: 6px;
    display: block;
  }
}

h3 {
  font-size: clamp(18px, 2vi, 23px);
  font-weight: var(--fw-400);
  line-height: 1.2;
}

.title, .desc {
  font-size: clamp(20px, 2.6vi, 28px);
}

.title {
  font-weight: var(--fw-500);
  color: var(--color-black);
}

.desc {
  font-weight: var(--fw-300);
  color: var(--color-white);
  line-height: 1.16;
}

.accent {
  color: var(--color-pink-400);
}

.decor {
  color: var(--color-blue-500);
  line-height: 1.2;
}

.decor--large {
  font-weight: var(--fw-500);
  color: var(--color-blue-400);
  font-size: clamp(20px, 2.6vi, 28px);
  line-height: 1.1;
}

a, button {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  overflow: hidden;
}

p {
  font-weight: var(--fw-400);
  line-height: 1.22;
}

input, textarea {
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 1;
}

input::placeholder, textarea::placeholder {
  color: var(--color-grey-870);
  font-size: 16px;
  line-height: 1;
}

.copyright {
  font-weight: var(--fw-300);
  color: var(--color-dark);
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.2;
}

.copyright br {
  display: none;
}

@media only screen and (max-width: 576px) {
  .copyright {
    text-align: center;
    line-height: 1.6;
  }

  .copyright br {
    display: block;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  padding: 10px 0 clamp(12px, 1.6vw, 20px);
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.nav {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__top {
  width: 55%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  margin-left: auto;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__top {
    width: 100%;
    margin-bottom: 0;
  }

  .nav__top .nav__btns {
    display: none;
  }
}

.nav .logo {
  max-width: 135px;
  width: 100%;
  margin-right: 2rem;
  padding-top: 14px;
}

@media only screen and (max-width: 992px) {
  .nav .logo {
    padding-top: 0;
  }
}

.nav__inner {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav__inner .nav__btns {
  display: none;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__inner .nav__btns {
    display: flex;
  }
}

.nav__list {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 32px;
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-size: 16px;
  line-height: 1.21;
  font-weight: var(--fw-300);
  color: var(--color-black);
  text-transform: capitalize;
  padding: 4px;
  transition: border-bottom .3s ease-in-out;
}

.nav__link:hover {
  border-bottom: 2px solid var(--color-black);
}

.nav__link.true {
  font-weight: var(--fw-400);
  color: var(--color-blue-400);
}

.nav__btns {
  align-items: center;
  gap: 20px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

[class].btn {
  border: 1px solid var(--color-black);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-main);
  background: var(--color-white);
  margin: 0;
  padding: 11px clamp(20px, 2.8vw, 40px);
  font-size: 16px;
  line-height: 1.21;
  transition-property: background, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
  color: var(--color-dark) !important;
}

[class].btn:hover, [class].btn--reverse, [class].btn.selected {
  background: var(--color-grey-700);
  color: var(--color-white) !important;
}

[class].btn--reverse, [class].btn--large {
  font-size: clamp(20px, 2vi, 23px);
  line-height: 1.2;
  font-weight: var(--fw-400);
}

[class].btn--reverse:hover {
  background: var(--color-white);
  color: var(--color-dark) !important;
}

[class].btn--large {
  padding: 19px clamp(26px, 3.4vw, 48px);
}

[class].btn--send {
  font-size: 18px;
  line-height: 1;
  font-weight: var(--fw-500);
  text-transform: initial;
  background-color: var(--color-black);
  border-radius: var(--radius-m);
  padding: 14px 23px;
  color: var(--color-white) !important;
}

[class].btn--no-border {
  border: 1px solid #0000;
}

[class].btn--no-border:hover {
  border: 1px solid var(--color-black);
}

[class].btn--mobile {
  margin-inline: auto;
  display: none;
}

@media only screen and (max-width: 992px) {
  [class].btn--mobile {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  [class].btn--mobile {
    width: 100%;
  }
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding: 0;
}

.hero__inner {
  padding: calc(var(--header-height)  - 10px) 0 32px;
  min-height: 727px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 2;
  position: relative;
}

.hero__content h1 {
  margin-bottom: 32px;
}

.hero__content .desc {
  max-width: 534px;
  width: 100%;
  margin-bottom: clamp(32px, 12vw, 171px);
}

.hero--reverse .hero__content h1, .hero--reverse .hero__content .desc {
  color: var(--color-dark);
}

.hero--tours .hero__content .desc {
  margin-bottom: clamp(32px, 12vw, 139px);
}

.hero--destinations .hero__inner {
  padding-top: calc(var(--header-height)  - 45px);
}

.hero--destinations .hero__content .desc {
  margin-bottom: clamp(32px, 12vw, 60px);
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 460px;
  }

  .hero--reverse .hero__content h1, .hero--reverse .hero__content .desc {
    color: var(--color-white);
  }

  .hero--destinations .hero__inner {
    padding-top: calc(var(--header-height)  - 10px);
    background-position: center;
  }
}

.box__nav {
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(20px, 2.8vw, 40px);
  display: flex;
}

.box__nav--small {
  margin-bottom: clamp(20px, 2.8vw, 32px);
}

.box__grid {
  grid-auto-rows: 1fr;
  align-items: stretch;
  display: grid;
}

.box__grid--three {
  grid-template-columns: repeat(3, 1fr);
}

.box__item {
  overflow: hidden;
}

.box__item article {
  width: 100%;
  position: relative;
}

.box__image img {
  width: 100%;
}

.box__tabs {
  margin-bottom: clamp(20px, 2.8vw, 40px);
}

.box__tabs .tabs__nav {
  justify-content: center;
  align-items: center;
  gap: 22px;
  display: flex;
}

.box--featured .box__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 29px 20px;
}

.box--featured .box__item {
  padding: 20px 20px 28px;
}

.box--featured .box__item article {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.box--featured .box__content {
  height: 100%;
  background-color: var(--color-grey-900);
  align-items: center;
  padding: 12px clamp(6px, 1.4vw, 20px);
  display: flex;
}

.box--featured .box__content a {
  width: 100%;
}

.box--featured .box__content a:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.box--featured .box__content a:hover h3 {
  color: var(--color-blue-400);
}

.box--featured .box__content h3 {
  color: var(--color-white);
  transition: color .4s ease-in-out;
}

.box--slider .box__nav, .box--tabs .box__nav {
  justify-content: center;
}

.box--slider .box__wrapper {
  max-width: 1160px;
  margin-inline: auto;
  padding: 28px 180px;
}

.box--slider .box__item {
  padding: 0;
}

.box--tabs .box__item {
  padding: clamp(0px, 2vw, 28px) clamp(0px, 2vw, 20px);
}

.box--hover .box__image {
  position: relative;
}

.box--hover .box__image img {
  width: 100%;
}

.box--hover .box__image:hover .box__hover {
  z-index: 5;
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}

.box--hover .box__hover {
  opacity: 0;
  z-index: -5;
  background-color: var(--color-grey-960);
  color: var(--color-white);
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: clamp(10px, 1.4vw, 20px);
  transition-property: opacity, z-index, height, transform;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: absolute;
  inset: 0;
  transform: translateY(100%);
}

.box--hover .box__content a:before {
  position: static;
}

.box--hover .box__content h3 {
  font-size: clamp(20px, 2.6vi, 28px);
  line-height: 1.16;
  font-weight: var(--fw-300);
}

.box--single .box__grid {
  max-width: 816px;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(20px, 7vw, 96px);
}

.box--single .box__item {
  padding: 28px 0;
}

.box--single .box__content {
  padding-block: 26px;
}

@media only screen and (max-width: 1180px) {
  .box--slider .box__wrapper {
    max-width: 92vw;
    padding: 28px 60px;
  }

  .box--slider .box__item {
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  .box .btn:not(.box .btn--mobile, .box .btn.request-btn, .box .btn.box__tab) {
    display: none;
  }

  .box--featured .box__item {
    padding: 0;
  }

  .box--btn .box__grid {
    margin-bottom: 32px;
  }

  .box--hover .box__hover {
    opacity: 1;
    z-index: 5;
    pointer-events: auto;
    text-align: center;
    transform: translateY(0);
  }

  .box--hover .box__hover .btn {
    display: block;
  }

  .box--hover .box__hover p {
    font-size: 16px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 768px) {
  .box__grid {
    grid-auto-rows: auto;
  }

  .box__grid--three .box__item:nth-child(3n) {
    grid-column: span 2;
  }

  .box--featured .box__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .box__grid--three {
    grid-template-columns: 1fr;
  }

  .box__grid--three .box__item:nth-child(3n) {
    grid-column: auto;
  }

  .box__tabs .tabs__nav {
    flex-direction: column;
  }

  .box__tabs .tabs__nav li, .box__tab {
    width: 100%;
  }

  .box--featured .box__grid {
    grid-template-columns: 1fr;
  }

  .box--featured .box__content {
    text-align: center;
  }

  .box--slider .box__wrapper {
    padding: 0;
  }

  .box--hover .box__image img {
    aspect-ratio: 4 / 3;
  }

  .box--hover .box__hover .btn {
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media only screen and (max-width: 475px) {
  .box--hover .box__image img {
    aspect-ratio: 3 / 4;
  }
}

.slider__nav {
  margin-bottom: clamp(32px, 4vw, 48px);
}

.slider__nav h2 {
  margin-bottom: 16px;
}

.slider__wrapper, .slider__list {
  max-width: 92vw;
  margin-inline: 0;
  position: relative;
}

.slider__slides {
  max-width: inherit;
  align-items: stretch;
}

.slider__slide {
  height: auto;
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

.slider__content .accent {
  margin-bottom: 20px;
}

.slider__image img {
  width: 100%;
}

.slider__texts p {
  color: var(--color-grey-870);
}

.slider__texts p:not(:last-of-type) {
  margin-bottom: 16px;
}

.slider__list {
  padding: clamp(20px, 3.8vw, 40px) 0;
  overflow: visible;
}

.slider__thumb {
  max-width: 200px;
}

.slider__thumb:not(:last-of-type) {
  margin-right: 16px;
}

.slider__thumb img {
  width: 100%;
  aspect-ratio: 5 / 4;
}

.slider__pagination {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  position: absolute;
}

.slider__pagination [class].swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  border: 1px solid var(--color-grey-400);
  background-color: var(--color-white);
  opacity: 1;
  margin: 0;
  transform: translate(0);
}

.slider__pagination [class].swiper-pagination-bullet-active {
  background-color: var(--color-blue-400);
  border-color: var(--color-blue-400);
}

.slider__btns {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

.slider__btn {
  height: 60px;
  width: 60px;
  background-image: url("../images/icons/arrow-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__btn--prev {
  transform: rotate(180deg);
}

.slider__btn.swiper-button-disabled {
  opacity: 0;
}

.slider--about .slider__wrapper {
  padding-top: 57px;
}

.slider--about .slider__content {
  max-width: 550px;
}

.slider--about .slider__content h2 {
  margin-bottom: 20px;
}

.slider--about .slider__texts {
  margin-bottom: clamp(32px, 15vw, 187px);
}

.slider--about .slider__pagination {
  top: 0;
  left: 50%;
  bottom: unset;
  width: -moz-fit-content;
  width: fit-content;
  transform: translateX(-50%);
}

.slider--discover {
  padding-bottom: 0;
}

.slider--discover .slider__slides {
  margin-bottom: clamp(30px, 5.6vw, 80px);
}

.slider--discover .slider__slide {
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 140px;
}

.slider--discover .slider__content {
  justify-content: space-between;
  gap: clamp(16px, 3.6vw, 32px);
  display: flex;
}

.slider--discover .slider__texts {
  max-width: 423px;
}

.slider--discover .slider__texts p {
  font-size: clamp(22px, 2.6vi, 28px);
  line-height: 1.14;
  font-weight: var(--fw-300);
  color: var(--color-black);
}

.slider--discover .slider__texts p:not(:last-of-type) {
  margin-bottom: 20px;
}

.slider--discover .slider__btns {
  z-index: 5;
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: absolute;
  top: 45%;
  right: 0;
  transform: translateY(-70%);
}

.slider--tours .slider__slide {
  transition: opacity .4s ease-in-out;
}

.slider--tours .slider__slide:not(.swiper-slide-active) {
  opacity: 0;
}

.slider--tours .slider__btn {
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider--tours .slider__btn--next {
  right: 20px;
}

.slider--tours .slider__btn--prev {
  left: 20px;
  transform: translateY(-50%)rotate(180deg);
}

.slider--overview h2 {
  margin-bottom: clamp(20px, 2.3vw, 32px);
}

.slider--overview .slider__content {
  max-width: 440px;
}

.slider--overview .slider__content .desc {
  color: var(--color-dark);
}

@media only screen and (max-width: 1280px) {
  .slider__list {
    overflow-x: hidden;
  }

  .slider--discover .slider__slide {
    padding-right: 0;
  }

  .slider--discover .slider__top {
    padding-right: 140px;
  }

  .slider--discover .slider__btns {
    top: 0;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1180px) {
  .slider--discover .slider__content {
    flex-direction: column;
  }

  .slider--discover .slider__texts {
    max-width: 100%;
  }

  .slider--tours .slider__btn--next {
    right: 0;
  }

  .slider--tours .slider__btn--prev {
    left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .slider__slide {
    flex-direction: column;
  }

  .slider__image img {
    aspect-ratio: 4 / 2;
  }

  .slider__thumb:not(:last-of-type) {
    margin-right: 6px;
  }

  .slider--about .slider__content {
    max-width: 100%;
  }

  .slider--reverse .slider__slide {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 576px) {
  .slider__image img {
    aspect-ratio: 4 / 3;
  }

  .slider__btns {
    gap: 0;
  }

  .slider__btn {
    width: 40px;
    height: 40px;
  }

  .slider .btn {
    width: 100%;
  }

  .slider--discover .slider__top {
    padding-right: 80px;
  }

  .slider--tours .slider__slides {
    margin-bottom: 20px;
  }

  .slider--tours .slider__btns {
    justify-content: center;
    gap: 20px;
  }

  .slider--tours .slider__btn {
    position: static;
    transform: translateY(0);
  }

  .slider--tours .slider__btn--prev {
    transform: translateY(0)rotate(180deg);
  }
}

.info {
  padding-top: clamp(40px, 7vw, 92px);
}

.info__nav {
  margin-bottom: clamp(40px, 7vw, 94px);
}

.info__nav h2 {
  margin-bottom: clamp(24px, 2.8vw, 40px);
}

.info__grid {
  max-width: 1150px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: clamp(40px, 8vw, 115px) clamp(24px, 4vw, 56px);
  display: grid;
}

.info__item {
  padding-left: 80px;
  position: relative;
}

.info__item:before {
  content: "";
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.info__item--expertise:before {
  background-image: url("../images/icons/expertise-icon.svg");
}

.info__item--accommodations:before {
  background-image: url("../images/icons/accommodations-icon.svg");
}

.info__item--personalized:before {
  background-image: url("../images/icons/personalized-icon.svg");
}

.info__item--sustainable:before {
  background-image: url("../images/icons/sustainable-icon.svg");
}

.info__item h3 {
  font-size: clamp(22px, 2.6vi, 28px);
  line-height: 1.1;
  font-weight: var(--fw-500);
  color: var(--color-blue-900);
  margin-bottom: 12px;
  padding-top: 5px;
}

.info__item p {
  color: var(--color-grey-870);
}

@media only screen and (max-width: 768px) {
  .info__grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

@media only screen and (max-width: 475px) {
  .info__item {
    padding-left: 50px;
  }

  .info__item:before {
    width: 40px;
    height: 40px;
  }
}

.detailed h2 {
  text-align: center;
  margin-bottom: clamp(20px, 2.8vw, 40px);
}

.detailed__list {
  gap: clamp(20px, 2.8vw, 40px) 0;
  display: flex;
}

.detailed__list:not(:last-of-type) {
  margin-bottom: clamp(20px, 2.8vw, 40px);
}

.detailed__item {
  width: 100%;
  padding: clamp(0px, 2vw, 28px) clamp(0px, 2vw, 20px);
}

.detailed__item--mountains {
  max-width: 833px;
}

.detailed__item--coastal {
  max-width: 407px;
}

.detailed__item--wild {
  max-width: 514px;
}

.detailed__item--island {
  max-width: 726px;
}

.detailed__top {
  position: relative;
}

.detailed__top img {
  height: revert-layer;
  width: 100%;
}

.detailed__label {
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.16;
  font-weight: var(--fw-300);
  color: var(--color-dark);
  background-color: var(--color-grey-400);
  border-top-right-radius: var(--radius-l);
  border-bottom-right-radius: var(--radius-l);
  padding: 8px 20px;
  position: absolute;
  bottom: 12px;
}

.detailed__links {
  background-color: var(--color-blue-300);
}

.detailed__link {
  border-bottom: 1px solid var(--color-grey-400);
  justify-content: space-between;
  align-items: center;
  padding-block: 10.5px;
  padding-inline: clamp(8px, 2vw, 20px) clamp(8px, 2.2vw, 24px);
  display: flex;
  position: relative;
}

.detailed__link h3 {
  font-size: 18px;
  font-weight: var(--fw-500);
  white-space: nowrap;
  transition: color .4s ease-in-out;
}

.detailed__link a {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.21;
  transition: color .4s ease-in-out;
}

.detailed__link a:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.detailed__link:hover h3, .detailed__link:hover a {
  color: var(--color-blue-600);
}

.detailed--single .detailed__item {
  max-width: 793px;
  padding: 0;
}

@media only screen and (max-width: 992px) {
  .detailed__list {
    flex-wrap: wrap;
  }

  .detailed__item[class] {
    max-width: 100%;
  }

  .detailed__top img {
    height: auto;
    aspect-ratio: 2 / 1;
  }

  .detailed__link {
    flex-direction: column;
    gap: 10px;
  }

  .detailed__link h3, .detailed__link a {
    white-space: initial;
    text-align: center;
  }
}

.article {
  overflow: auto;
}

.article__tabs {
  width: 92vw;
  align-items: center;
  display: flex;
  overflow-x: scroll;
}

.article__tabs li {
  display: inline;
}

.article__tab {
  font-size: 16px;
  line-height: 1.21;
  font-weight: var(--fw-300);
  white-space: nowrap;
  border-bottom: 3px solid var(--color-grey-400);
  margin-bottom: clamp(20px, 2.8vw, 40px);
  padding: 12px clamp(20px, 2.8vw, 40px);
  transition: color .4s ease-in-out;
  display: block;
}

.article__tab.selected, .article__tab:hover {
  border-bottom-color: var(--color-blue-600);
  color: var(--color-blue-600);
}

.article__name, .article__image {
  margin-bottom: clamp(20px, 2.8vw, 40px);
}

.article__name {
  font-size: clamp(20px, 2.8vi, 40px);
  line-height: .92;
  font-weight: var(--fw-300);
}

.article__body {
  max-width: 914px;
  width: 100%;
}

.article__content * {
  font-size: clamp(16px, 2.2vi, 24px);
  line-height: 1.16;
  font-weight: var(--fw-300);
}

.article__content > :not(:last-child), .article__content:not(:last-of-type) {
  margin-bottom: 22px;
}

.article__content ul, .article__content ol {
  padding-left: 36px;
}

.article__content li {
  display: list-item;
}

.article__content ol li {
  list-style: auto;
}

.article__content ol li:not(:last-of-type) {
  margin-bottom: 26px;
}

.article__content ul li {
  list-style: disc;
}

.article__content ul li:not(:last-of-type) {
  margin-bottom: clamp(20px, 3vw, 34px);
}

.article__content b {
  font-weight: var(--fw-600);
}

.article__accent, .article a {
  color: var(--color-blue-600);
  font-weight: var(--fw-400);
}

.article a {
  display: inline;
}

@media only screen and (max-width: 1180px) {
  .article__tabs {
    margin-bottom: 22px;
  }

  .article__image img {
    aspect-ratio: 4 / 2;
  }
}

.single {
  padding: 32px 0;
}

.single__back {
  min-height: 24px;
  font-size: clamp(16px, 2vw, 18px);
  font-weight: var(--fw-300);
  padding-left: 32px;
  display: block;
  position: relative;
}

.single__back:before {
  content: "";
  width: 24px;
  height: 24px;
  filter: none;
  background-image: url("../images/icons/back-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: filter .4s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.single__back:hover:before {
  filter: brightness(0) saturate() invert(47%) sepia() saturate(362%) hue-rotate(148deg) brightness(86%) contrast(92%);
}

.single__back + .accent {
  margin-bottom: clamp(20px, 2.8vw, 40px);
}

.single__intro {
  font-weight: var(--fw-300);
  margin-bottom: clamp(20px, 7vw, 96px);
}

.single__intro, .single__title, .single__name, .single__desc {
  font-size: clamp(20px, 2.6vi, 28px);
  line-height: 1.16;
}

.single__back, .single__image, .single__body {
  margin-bottom: clamp(24px, 3.8vw, 50px);
}

.single__title, .single__content {
  padding: 12px clamp(6px, 1.6vw, 20px);
}

.single__title, .single__name {
  font-weight: var(--fw-500);
}

.single__title {
  text-transform: capitalize;
  background-color: var(--color-grey-900);
  margin-bottom: 20px;
}

.single__content {
  gap: 10px;
  display: flex;
}

.single__content:not(:last-of-type) {
  margin-bottom: 20px;
}

.single__body {
  max-width: 800px;
  width: 100%;
}

.single__name {
  max-width: 200px;
  width: 100%;
  color: var(--color-dark);
}

.single__desc, .single__accent, .single__detail p {
  font-weight: var(--fw-300);
}

.single__desc:not(:last-child), .single__accent:not(:last-child), .single__detail p:not(:last-child) {
  margin-bottom: 10px;
}

.single__accent, .single__detail {
  padding: 0 8px;
}

.single__accent {
  background-color: var(--color-blue-300);
}

@media only screen and (max-width: 576px) {
  .single__content {
    flex-direction: column;
  }

  .single__image img {
    aspect-ratio: 4 / 3;
  }

  .single__accent, .single__detail, .single__content {
    padding: 0;
  }

  .single .btn {
    width: 100%;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.request {
  background-color: var(--color-dark);
}

.request h2 {
  color: var(--color-white);
  text-align: center;
  margin-bottom: clamp(24px, 3.8vw, 48px);
}

.request__form {
  max-width: 800px;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--shadow-primary);
  border-radius: var(--radius-m);
  grid-template-columns: 1fr;
  margin-inline: auto;
  padding: clamp(20px, 2.8vw, 34px) clamp(10px, 2.8vw, 32px);
  display: grid;
}

.request__form label {
  display: block;
}

.request__form label:not(.request__textarea) {
  margin-bottom: 16px;
}

.request__form label.request__textarea {
  margin-bottom: 24px;
}

.request__form input, .request__form textarea {
  width: 100%;
  background-color: var(--color-grey-200);
  border-radius: var(--radius-m);
  padding: 14px clamp(8px, 1.2vw, 16px);
  display: block;
}

.request__form textarea {
  min-height: 156px;
  resize: none;
  border: none;
}

@media only screen and (max-width: 768px) {
  .request .btn {
    width: 100%;
  }
}

.policy {
  background-color: var(--color-blue-600);
}

.policy h1 {
  text-align: center;
  margin-bottom: clamp(20px, 2.8vw, 40px);
  font-size: clamp(26px, 2.8vi, 40px);
  line-height: 1.1;
}

.policy__inner {
  background-color: var(--color-white);
  border-radius: var(--radius-xxl);
  padding: clamp(20px, 4.2vw, 60px);
}

.policy h2 {
  font-size: clamp(20px, 2.6vi, 28px);
  line-height: 1.1;
  font-weight: var(--fw-500);
  color: var(--color-dark);
  margin-bottom: 24px;
}

.policy .policy__content:not(:last-child), .policy .policy__content > :not(:last-child, h2) {
  margin-bottom: clamp(24px, 3.2vw, 48px);
}

.policy ul {
  padding-left: clamp(16px, 3vw, 32px);
}

.policy li {
  padding-left: 32px;
  position: relative;
}

.policy li:before {
  content: "✔";
  position: absolute;
  left: 0;
}

.policy li:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  color: var(--color-blue-600);
  text-underline-offset: 6px;
  word-break: break-all;
  text-decoration: underline;
  display: inline;
}

.policy a:hover {
  color: var(--color-pink-400);
  text-decoration: underline;
}

.policy strong {
  font-size: clamp(18px, 3vw, 22px);
  font-weight: var(--fw-500);
}

@media only screen and (max-width: 992px) {
  .policy__inner {
    border-radius: var(--radius-l);
  }
}

.footer {
  padding: clamp(20px, 6.2vw, 87px) 0;
}

.footer .container {
  max-width: 1280px;
  padding-inline: 0;
}

.footer__wrapper {
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  display: flex;
}

.footer__items {
  justify-content: space-between;
  gap: clamp(20px, 4.2vw, 60px);
  display: flex;
}

.footer__items a {
  padding: 0 4px;
  font-size: 16px;
  line-height: 1.21;
}

@media only screen and (max-width: 1280px) {
  .footer .container {
    padding: 0 var(--container-offset);
    max-width: var(--container-width);
  }
}

@media only screen and (max-width: 992px) {
  .footer__wrapper {
    flex-direction: column-reverse;
  }
}

/*# sourceMappingURL=main.css.map */
