@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(18px, 2vi, 23px);
  line-height: 1.2;
  letter-spacing: 0;
  color: var(--color-grey-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &--destinations {
    section#featured {
      .box__nav {
        justify-content: center;
      }

      .btn {
        display: none;
      }
    }
  }

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  &--wide {
    max-width: var(--container-wide);
  }
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  padding-top: var(--header-height);
}

.section {
  padding: clamp(40px, 7vw, 100px) 0;

  &--small {
    padding: clamp(30px, 5.6vw, 80px) 0;
  }
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  @include media(tablet) {
    &::before {
      background: var(--gradient-main);
    }
  }
}

.tabs {
  &__btn {
    cursor: pointer;
  }
}