@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 10px 0 clamp(12px, 1.6vw, 20px);
  width: 100%;
  background-color: var(--color-white);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-100%);
  }
}