@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: calc(var(--header-height) - 10px) 0 32px;
    min-height: 727px;
    background-position: 100% 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    position: relative;
    z-index: 2;

    h1 {
      margin-bottom: 32px;
    }

    .desc {
      max-width: 534px;
      width: 100%;
      margin-bottom: clamp(32px, 12vw, 171px);
    }
  }

  &--reverse {
    .hero__content {
      h1,
      .desc {
        color: var(--color-dark);
      }
    }
  }

  &--tours {
    .hero__content {
      .desc {
        margin-bottom: clamp(32px, 12vw, 139px);
      }
    }
  }

  &--destinations {
    .hero__inner {
      padding-top: calc(var(--header-height) - 45px);
    }

    .hero__content {
      .desc {
        margin-bottom: clamp(32px, 12vw, 60px);
      }
    }
  }

  @include media(tablet) {
    &__inner {
      min-height: 460px;
    }

    &--reverse {
      .hero__content {
        h1,
        .desc {
          color: var(--color-white);
        }
      }
    }

    &--destinations {
      .hero__inner {
        padding-top: calc(var(--header-height) - 10px);
        background-position: center;
      }
    }
  }
}