@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 11px clamp(20px, 2.8vw, 40px);
  border: 1px solid var(--color-black);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  font-size: 16px;
  line-height: 1.21;
  color: var(--color-dark) !important;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-main);
  background: var(--color-white);
  transition-property: background, color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &:hover,
  &--reverse,
  &.selected {
    color: var(--color-white) !important;
    background: var(--color-grey-700);
  }

  &--reverse,
  &--large {
    font-size: clamp(20px, 2vi, 23px);
    line-height: 1.2;
    font-weight: var(--fw-400);

  }

  &--reverse {
    &:hover {
      color: var(--color-dark) !important;
      background: var(--color-white);
    }
  }

  &--large {
    padding: 19px clamp(26px, 3.4vw, 48px);
  }

  &--send {
    padding: 14px 23px;
    font-size: 18px;
    line-height: 1;
    font-weight: var(--fw-500);
    color: var(--color-white) !important;
    text-transform: initial;
    background-color: var(--color-black);
    border-radius: var(--radius-m);
  }

  &--no-border {
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--color-black);
    }
  }

  &--mobile {
    display: none;
    margin-inline: auto;

    @include media(tablet) {
      display: block;
    }

    @include media(mobile-m) {
      width: 100%;
    }
  }
}