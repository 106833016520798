@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.article {
  overflow: auto;

  &__tabs {
    width: 92vw;
    overflow-x: scroll;
    @include flex-v-center;

    li {
      display: inline;
    }
  }

  &__tab {
    display: block;
    margin-bottom: clamp(20px, 2.8vw, 40px);
    padding: 12px clamp(20px, 2.8vw, 40px);
    font-size: 16px;
    line-height: 1.21;
    font-weight: var(--fw-300);
    white-space: nowrap;
    border-bottom: 3px solid var(--color-grey-400);
    transition: color 0.4s ease-in-out;

    &.selected,
    &:hover {
      border-bottom-color: var(--color-blue-600);
      color: var(--color-blue-600)
    }
  }

  &__name,
  &__image {
    margin-bottom: clamp(20px, 2.8vw, 40px);
  }

  &__name {
    font-size: clamp(20px, 2.8vi, 40px);
    line-height: 0.92;
    font-weight: var(--fw-300);
  }

  &__body {
    max-width: 914px;
    width: 100%;
  }

  &__content {
    * {
      font-size: clamp(16px, 2.2vi, 24px);
      line-height: 1.16;
      font-weight: var(--fw-300);
    }

    & > *:not(:last-child),
    &:not(:last-of-type) {
      margin-bottom: 22px;
    }

    ul, ol {
      padding-left: 36px;
    }

    li {
      display: list-item;
    }

    ol li {
      list-style: auto;

      &:not(:last-of-type) {
        margin-bottom: 26px;
      }
    }

    ul li {
      list-style: disc;

      &:not(:last-of-type) {
        margin-bottom: clamp(20px, 3vw, 34px);
      }
    }

    b {
      font-weight: var(--fw-600);
    }
  }

  &__accent,
  a {
    color: var(--color-blue-600);
    font-weight: var(--fw-400);
  }

  a {
    display: inline;
  }

  @include media(tablet-wide) {
    &__tabs {
     margin-bottom: 22px;
    }

    &__image {
      img {
        aspect-ratio: 4 / 2;
      }
    }
  }
}