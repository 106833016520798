@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1 {
  font-size: clamp(32px, 5vi, 70px);
  line-height: 0.85;
  font-weight: var(--fw-600);
  color: var(--color-white);
  text-transform: uppercase;

  @include media(tablet) {
    line-height: 1.2;
  }
}

h2,
.accent {
  font-size: clamp(26px, 2.8vi, 40px);
  font-weight: var(--fw-300);
  line-height: 1;

  span {
    color: var(--color-blue-600);

    @include media(tablet) {
      display: block;
      padding-top: 6px;
    }
  }
}

h3 {
  font-size: clamp(18px, 2vi, 23px);
  font-weight: var(--fw-400);
  line-height: 1.2;
}

.title,
.desc {
  font-size: clamp(20px, 2.6vi, 28px);
}

.title {
  font-weight: var(--fw-500);
  color: var(--color-black);
}

.desc {
  font-weight: var(--fw-300);
  line-height: 1.16;
  color: var(--color-white);
}

.accent {
  color: var(--color-pink-400);
}

.decor {
  line-height: 1.2;
  color: var(--color-blue-500);

  &--large {
    font-weight: var(--fw-500);
    font-size: clamp(20px, 2.6vi, 28px);
    line-height: 1.1;
    color: var(--color-blue-400);
  }
}

a,
button {
  display: block;
  width: fit-content;
  overflow: hidden;
}

p {
  font-weight: var(--fw-400);
  line-height: 1.22;
}

input,
textarea {
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 1;

  &::placeholder {
    color: var(--color-grey-870);
    font-size: 16px;
    line-height: 1;
  }
}

.copyright {
  font-weight: var(--fw-300);
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.2;
  color: var(--color-dark);

  br {
    display: none;
  }

  @include media(mobile-m) {
    line-height: 1.6;
    text-align: center;

    br {
      display: block;
    }
  }
}
