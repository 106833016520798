@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  @include flex-all-sb;

  position: relative;
  flex-direction: column;

  &__top {
    @include flex-all-sb;

    width: 55%;
    margin-left: auto;
    margin-bottom: 27px;

    @include media(tablet) {
      width: 100%;
      margin-bottom: 0;

      .nav__btns {
        display: none;
      }
    }
  }

  .logo {
    max-width: 135px;
    width: 100%;
    margin-right: rem(32);
    padding-top: 14px;

    @include media(tablet) {
      padding-top: 0;
    }
  }

  &__inner {
    @include flex-all-center;
    width: 100%;

    .nav__btns {
      display: none;
    }

    @include media(tablet) {
      position: fixed;
      left: 0;
      top: calc(var(--header-height) * 1);
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 30px 16px;
      height: calc(var(--vh));
      background-color: var(--color-white);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;

      .nav__btns {
        display: flex;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;

    @include media(tablet) {
      z-index: -1;
      flex-direction: column;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 32px;

      @include media(tablet) {
        margin-right: 0;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    padding: 4px;
    font-size: 16px;
    line-height: 1.21;
    font-weight: var(--fw-300);
    color: var(--color-black);
    text-transform: capitalize;
    transition: border-bottom 0.3s ease-in-out;

    &:hover {
      border-bottom: 2px solid var(--color-black);
    }

    &.true {
      font-weight: var(--fw-400);
      color: var(--color-blue-400);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 20px;

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }
}